
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto; 
}

#app {
  overflow: hidden;
  touch-action: pan-up;
  color: #fafafa;
  height: 100vh; 
  font-family: 'Indie Flower', sans-serif;
  text-align: center;
  /* text-shadow: 0 0 5px #ffffff, 0 0 20px #000, 0 0 30px #000; */
}
.icon-link {
  display: inline-block;
  transition: transform 0.3s;
}

.icon-link:hover {
  transform: scale(1.1); 
}
#app h1 {
  --fontSize: 60px;
  --lineHeight: 80px;
  width: auto;
  height: calc(2 * var(--lineHeight));
  line-height: var(--lineHeight);
  margin: calc(50vh - var(--lineHeight)) auto 0;
  font-size: var(--fontSize);
  text-transform: uppercase;
}

#app a {
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
}

#app canvas {
  display: block;
  position: fixed;
  z-index: -1;
  top: 0;
}
#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover; 
}
.balloon {
  position: absolute;
  top: 70%;
  left: 12%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 150px;
  background-image: url('./q.png'); 
  background-size: contain;
  animation: float 2s ease-in-out infinite alternate; 
}

@keyframes float {
  from { transform: translate(-50%, -50%); }
  to { transform: translate(-50%, -25%); }
}